import { useRef } from 'react';

import { HeadingLevelProvider } from '@/components/common/Heading/context';
import { SkipToContentButton } from '@/components/common/SkipToContentButton';
import { Footer } from '@/components/layout/Footer';
import { MainContainer } from '@/components/layout/Layout/Layout.styles';
import { NavigationBar } from '@/components/layout/NavigationBar';
import { SharedLayout } from '@/components/layout/SharedLayout';
import { useNewNavigation } from '@/lib/useNewNavigation';
import { PageCommonProvider } from '@/lib/usePageCommon';

import type { SharedLayoutProps } from '@/components/layout/SharedLayout';
import type { RecommendationContext } from '@/lib/dynamicYield';
import type { LayoutControls } from '@/lib/usePageCommon';
import type { SanityAudienceType, SanityNavigationType } from '@/types/sanity';
import type { Navigation_exType, SiteSettings } from '@/types/shared';

interface LayoutProps extends SharedLayoutProps {
  preview?: boolean;
  siteSettings: SiteSettings;
  navigation?: SanityNavigationType;
  audienceRef?: SanityAudienceType;
  navigations_ex?: Navigation_exType[];
  layout?: LayoutControls;
  recommendationContext: RecommendationContext;
  isStickyNavigation?: boolean;
}

export const Layout = ({
  children,
  SEO,
  preview = false,
  siteSettings,
  localeTranslations,
  canonicalPagePath,
  navigation,
  audienceRef,
  navigations_ex,
  recommendationContext,
  layout,
  isStickyNavigation,
}: LayoutProps) => {
  const mainRef = useRef<HTMLElement | null>(null);

  const navigation_ex = useNewNavigation(
    navigations_ex,
    siteSettings,
    audienceRef,
  );

  return (
    <PageCommonProvider
      siteSettings={siteSettings}
      dynamicYield={{ recommendationContext }}
      layout={layout}
      preview={preview}
    >
      <SharedLayout
        localeTranslations={localeTranslations}
        canonicalPagePath={canonicalPagePath}
        SEO={SEO}
      >
        <HeadingLevelProvider>
          <SkipToContentButton focusElement={mainRef} />

          {(navigation_ex || navigation) && (
            <NavigationBar
              navigation={navigation}
              navigation_ex={navigation_ex}
              audiences={
                siteSettings?.isAudienceSwitcherEnabled &&
                siteSettings?.newNavigation &&
                navigations_ex
                  .map((navigation_ex) => navigation_ex.audience)
                  .filter((audience) => audience !== null)
              }
              siteSettings={siteSettings}
              isSticky={isStickyNavigation}
            />
          )}

          <MainContainer ref={mainRef} tabIndex={-1}>
            {children}
          </MainContainer>

          <Footer {...siteSettings} />
        </HeadingLevelProvider>
      </SharedLayout>
    </PageCommonProvider>
  );
};
